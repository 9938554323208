

    import {Options, prop, Vue} from 'vue-class-component';
    import {toClipboard} from '@soerenmartius/vue3-clipboard';
    import {recordingFile} from "@/util";
    import VueQr3 from 'vue3-qr-code-styling';

    class Props {
        id: string = prop({
            required: true,
        });
    }

    @Options({components: {VueQr3}})
    export default class ShareRecording extends Vue.with(Props) {

        id!: string;

        url: string = '';
        downloadUrl: string = '';

        actionDetails: string = 'none';

        embedCode: string = '';

        created() {
            this.url = `${window.location.protocol}//${window.location.host}/${this.id}`;
            this.downloadUrl = recordingFile(this.id);
            this.embedCode = this.createEmbedCode();
        }

        copyUrl() {
            toClipboard(this.url);
            // @ts-ignore
            const input: HTMLInputElement = this.$refs['input'];
            input.focus();
            input.select();
        }

        copyEmbedCode() {
            toClipboard(this.embedCode);
            // @ts-ignore
            const textarea: HTMLInputElement = this.$refs['embed'];
            textarea.focus();
            textarea.select();
        }

        createEmbedCode(): string {
            const embedUrl = `${window.location.protocol}//${window.location.host}/embed.html#${this.id}`;
            return `<div><iframe width="300" height="60" src="${embedUrl}" frameborder="0" allow="autoplay"></iframe><br><a href="${this.url}" title="RecArena" target="_blank">Auf RecArena anhören</a></div>`;
        }
    }
