
    import {Options, Vue} from 'vue-class-component';
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    import MainSection from "@/components/MainSection.vue";

    @Options({
        components: {
            MainSection,
            Footer,
            Header,
        },
    })
    export default class App extends Vue {
    }
