import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e50ac55e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Recording = _resolveComponent("Recording")!
  const _component_Recorder = _resolveComponent("Recorder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.recordingId)
      ? (_openBlock(), _createBlock(_component_Recording, {
          key: 0,
          id: _ctx.recordingId
        }, null, 8, ["id"]))
      : (_openBlock(), _createBlock(_component_Recorder, { key: 1 }))
  ]))
}