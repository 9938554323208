

    import {Options, Vue} from 'vue-class-component';
    import AudioRecorder from '@/AudioRecorder';
    import {formatSeconds} from '@/util';
    import config from '@/config';
    import Player from "@/components/Player.vue";
    import ShareRecording from "@/components/ShareRecording.vue";
    import Error from "@/components/Error.vue";
    import TermsOfUse from "@/components/TermsOfUse.vue";

    type RecorderState = 'initial' | 'recording' | 'playback';

    const MAX_SECONDS: number = 10 * 60;
    const UPLOAD_URL: string = config.uploadUrl;

    @Options({
        components: {TermsOfUse, Error, ShareRecording, Player},
    })
    export default class Recorder extends Vue {

        state: RecorderState = 'initial';

        error: string | null = null;
        unavailable: boolean = false;

        recorder: AudioRecorder = new AudioRecorder(MAX_SECONDS);

        paused: boolean = false;
        saving: boolean = false;

        recordingId: string | null = null;

        termsAccepted: boolean = false;
        showTerms: boolean = false;

        reset(): void {
            this.state = 'initial';
            this.error = null;
            this.recorder.reset();
            this.saving = false;
            this.recordingId = null;
        }

        mounted() {

            window.navigator.mediaDevices.enumerateDevices()
                .then(infos => {
                    if (!infos.find(info => info.kind === 'audioinput')) {
                        this.error = 'Kein Mikrofon gefunden. Bitte schliesse ein Mikrofon an.';
                        this.unavailable = true;
                    }
                });
        }

        unmounted() {
            this.reset();
        }

        start(): void {

            if (!this.termsAccepted) {
                this.showTerms = true;
                return;
            }

            if (this.state !== 'initial' || this.unavailable) {
                return;
            }

            this.recorder.reset();

            this.recorder
                .start()
                .then(() => {
                    this.state = 'recording';
                    this.$nextTick(() => {
                        // @ts-ignore
                        this.recorder.setFrequenciesCanvas(this.$refs['frequencies']);
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.error = 'Aufnahme nicht möglich. Bitte erlaube den Zugriff auf das Mikrofon.';
                });
        }

        elapsedRecordingTime(): string {
            return formatSeconds(this.recorder.getRordingSeconds());
        }

        pause(): void {
            this.recorder
                .pause()
                .then(() => this.paused = true);
        }

        resume(): void {
            this.recorder
                .resume()
                .then(() => this.paused = false);
        }

        stop(): void {

            if (this.state !== 'recording') {
                return;
            }

            if (!this.recorder) {
                this.reset();
                return;
            }

            this.recorder.stop();

            this.state = 'playback';
            this.paused = false;
        }

        save() {

            if (this.state !== 'playback' || !this.recorder) {
                this.reset();
                return;
            }

            this.saving = true;
            this.error = null;

            const audioBlob = this.recorder.getAudioBlob();

            const formData = new FormData();
            formData.append('file', audioBlob);

            const onError = () => {
                this.error = 'Die Aufnahme konnte leider nicht gespeichert werden. Bitte versuche es erneut.'
                this.saving = false;
            }

            const req = new XMLHttpRequest();
            req.addEventListener('load', () => {
                if (req.status !== 200) {
                    onError();
                    return;
                }
                try {
                    this.recordingId = JSON.parse(req.response).id;
                } catch (e) {
                    onError();
                }
            });
            req.addEventListener('error', onError);
            req.open('POST', UPLOAD_URL);
            req.send(formData);
        }

        acceptTerms() {
            this.showTerms = false;
            this.termsAccepted = true;
        }
    }
