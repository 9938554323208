import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43413448"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recording" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Player = _resolveComponent("Player")!
  const _component_ShareRecording = _resolveComponent("ShareRecording")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.audio)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Player, { audio: _ctx.audio }, null, 8, ["audio"]),
          (!_ctx.error)
            ? (_openBlock(), _createBlock(_component_ShareRecording, {
                key: 0,
                id: _ctx.id
              }, null, 8, ["id"]))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createBlock(_component_Error, {
                key: 1,
                error: _ctx.error
              }, null, 8, ["error"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}