import lamejs from 'lamejs';
import {float32toInt16} from '@/util';

const BUFFER_SIZE = 1024;
const KBPS = 192;

window.AudioContext = window.AudioContext ||
    // @ts-ignore
    window.webkitAudioContext;

class AudioRecorder {

    private maxSeconds: number;

    private recordingTimer: RecordingTimer = new RecordingTimer();

    private context: AudioContext | null = null;

    private source: MediaStreamAudioSourceNode | null = null;

    private processor: ScriptProcessorNode | null = null;

    private encoder: any = null;

    private audioData: any[] = [];

    private audioBlob: Blob | null = null;

    private audioUrl: string | null = null;

    private audio: HTMLAudioElement | null = null;

    private analyser: AnalyserNode | null = null;

    constructor(maxSeconds: number) {
        this.maxSeconds = maxSeconds;
    }

    reset() {
        this.recordingTimer.reset();
        this.context = null;
        this.source = null;
        this.processor = null;
        this.encoder = null;
        this.audioData = [];
        this.audioBlob = null;
        this.audioUrl = null;
        this.audio = null;
        this.analyser = null;
    }

    start(): Promise<void> {

        return window.navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {

                this.context = new AudioContext();

                this.source = this.context.createMediaStreamSource(stream);
                this.processor = this.context.createScriptProcessor(BUFFER_SIZE, 1, 1);

                // prepare analyser to measure input amplitude
                this.analyser = this.context.createAnalyser();
                this.analyser.fftSize = 256;
                this.source.connect(this.analyser);

                this.recordingTimer = new RecordingTimer();
                this.recordingTimer.start();
                window.setInterval(() => {
                    if (this.recordingTimer.elapsed() >= this.maxSeconds) {
                        this.stop();
                    }
                }, 1000);

                this.audioData = [];

                this.source.connect(this.processor);
                this.processor.connect(this.context.destination);

                const encoder = new lamejs.Mp3Encoder(1, this.context.sampleRate, KBPS);
                this.encoder = encoder;

                this.processor.addEventListener('audioprocess', e => {
                    const int16Buff = float32toInt16(e.inputBuffer.getChannelData(0));
                    const chunk = encoder.encodeBuffer(int16Buff);
                    if (chunk.length > 0) {
                        this.audioData.push(chunk);
                    }
                });
            });
    }

    pause(): Promise<void> {
        if (!this.context) {
            return Promise.reject('Not currently recording');
        }
        return this.context.suspend()
            .then(() => this.recordingTimer.pause());
    }

    resume(): Promise<void> {
        if (!this.context) {
            return Promise.reject('Not currently recording');
        }
        return this.context.resume()
            .then(() => this.recordingTimer.resume());
    }

    stop(): void {
        if (!this.encoder) {
            return;
        }

        this.source!.disconnect();
        this.processor!.disconnect();

        const rest = this.encoder!.flush();
        if (rest.length > 0) {
            this.audioData.push(new Int8Array(rest));
        }

        this.audioBlob = new Blob(this.audioData, {type: 'audio/mp3'});
        this.audioUrl = URL.createObjectURL(this.audioBlob);
        this.audio = new Audio(this.audioUrl);

        this.encoder = null;
        this.context?.close();

        this.recordingTimer.reset();
    }

    getAudio(): HTMLAudioElement {
        return this.audio!;
    }

    getAudioBlob(): Blob {
        return this.audioBlob!;
    }

    getRordingSeconds(): number {
        return this.recordingTimer.elapsed();
    }

    setFrequenciesCanvas(canvas?: HTMLCanvasElement) {
        canvas && this.initFrequenciesCanvas(canvas);
    }

    private initFrequenciesCanvas(canvas: HTMLCanvasElement) {

        if (!this.analyser) {
            return;
        }

        this.analyser.smoothingTimeConstant = 0.85;

        const bufferLength = this.analyser.frequencyBinCount;
        const data = new Uint8Array(bufferLength);

        const canvasCtx = canvas.getContext("2d")!;
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

        const draw = () => {

            if (!this.analyser) {
                return;
            }

            const drawVisual = requestAnimationFrame(draw);

            this.analyser.getByteFrequencyData(data);

            canvasCtx.fillStyle = 'rgb(255, 255, 255)';
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            const barWidth = (canvas.width / bufferLength) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                barHeight = data[i];

                canvasCtx.fillStyle = 'rgba(200, 200, 200, .5)';
                canvasCtx.fillRect(x, canvas.height - barHeight / 2, barWidth, barHeight / 2);

                x += barWidth + 1;
            }
        };

        draw();
    }
}

class RecordingTimer {

    private interval: number | null = null;

    private seconds: number = 0;

    start(): void {
        this.seconds = 0;
        this.resume();
    }

    pause(): void {
        this.interval && window.clearInterval(this.interval);
    }

    resume(): void {
        this.interval = window.setInterval(() => this.seconds += 1, 1000);
    }

    reset(): void {
        this.pause();
        this.seconds = 0;
    }

    elapsed(): number {
        return this.seconds;
    }
}

export default AudioRecorder;