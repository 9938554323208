import config from '@/config';

export function formatSeconds(_seconds?: number): string {

    const seconds = (_seconds && !isNaN(_seconds) && _seconds !== Infinity)
        ? Math.ceil(_seconds) : 0;

    const min = Math.floor(seconds / 60);
    const sec = seconds - (min * 60);

    return `${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
}

export function recordingFile(recordingId: string) {
    return config.recordingFile(recordingId);
}

export function float32toInt16(float32: Float32Array): Int16Array {
    const int16 = new Int16Array(float32.length);
    for (let i = 0, len = float32.length; i < len; i++) {
        if (float32[i] < 0) {
            int16[i] = 0x8000 * float32[i];
        } else {
            int16[i] = 0x7FFF * float32[i];
        }
    }
    return int16;
}