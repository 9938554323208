
    import {Options, Vue} from 'vue-class-component';

    @Options({})
    export default class TermsOfUse extends Vue {

        accept() {
            this.$emit('accept');
        }
    }
