class Config {

    uploadUrl: string = 'https://schularena.paloma.one/api/recordings/';

    recordingFile(recordingId: string) {
        return `https://schularena.paloma.one/files/recordings/${recordingId}`;
    }

    // uploadUrl: string = 'https://schu-stage.paloma.one/api/recordings/';
    //
    // recordingFile(recordingId: string) {
    //     return `https://schu-stage.paloma.one/files/recordings/${recordingId}`;
    // }

    // uploadUrl: string = 'https://local.paloma.one/api/recordings/';
    //
    // recordingFile(recordingId: string) {
    //     return `https://local.paloma.one/files/recordings/${recordingId}`;
    // }

}

export default new Config();