

    import {prop, Vue} from 'vue-class-component';

    class Props {
        error: string = prop({
            required: true,
        });
    }

    export default class Error extends Vue.with(Props) {
    }
