

    import {Options, prop, Vue} from 'vue-class-component';
    import {formatSeconds} from '@/util';

    class Props {
        audio: HTMLAudioElement = prop({
            required: true,
        });
    }

    type PlayerState = 'initial' | 'playing' | 'paused' | 'error';

    @Options({})
    export default class Player extends Vue.with(Props){

        audio!: HTMLAudioElement;

        state: PlayerState = 'initial';

        currentTime: string = '--:--';
        totalTime: string = '--:--';
        progress: number = 0;

        mounted() {

            const wait = window.setInterval(() => {
                if (this.audio) {

                    // fix 'infinite' duration
                    // if (this.audio.duration === Infinity) {
                    //     this.audio.ontimeupdate = () => {
                    //         this.audio.ontimeupdate = () => {
                    //             return;
                    //         }
                    //         this.audio.currentTime = 0;
                    //     }
                    //     this.audio.currentTime = 1e101;
                    // }

                    window.clearInterval(wait);
                    this.init();
                }
            }, 100);
        }

        unmounted() {
          this.audio && this.audio.pause();
        }

        init() {

            if (this.audio.error) {
                this.state = 'error';
            }

            this.refreshTimes();

            this.audio.addEventListener('timeupdate', () => {
                this.refreshTimes();
            });

            this.audio.addEventListener('ended', () => {
                this.stop();
            });

            this.audio.addEventListener('error', () => {
                this.state = 'error';
            });
        }

        refreshTimes() {
            this.currentTime = formatSeconds(this.audio.currentTime);
            this.totalTime = formatSeconds(this.audio.duration);
            this.progress = Math.round((this.audio.currentTime / this.audio.duration) * 100);
        }

        play(): void {
            if (this.state !== 'initial' && this.state !== 'paused') {
                return;
            }
            this.audio.play();
            this.state = 'playing';
        }

        pause(): void {
            if (this.state !== 'playing') {
                return;
            }
            this.audio.pause();
            this.state = 'paused';
        }

        stop() {
            if (this.state !== 'playing' && this.state !== 'paused') {
                return;
            }
            this.audio.pause();
            this.audio.currentTime = 0;
            this.state = 'initial';
        }

        seekClick(e: MouseEvent) {
            const clickOffset: number = e.clientX;
            this.seek(clickOffset);
        }

        seekTouch(e: TouchEvent) {
            if (e.changedTouches.length === 0) {
                return;
            }
            const offset = e.changedTouches.item(0)!.clientX;
            this.seek(offset);
        }

        seek(clickOffset: number) {

            // @ts-ignore
            const track: HTMLElement = this.$refs['track'];
            const trackOffset = track.offsetLeft;
            const trackWidth = track.clientWidth;

            const pos = clickOffset - trackOffset;
            const rel = pos / trackWidth;
            const time = this.audio.duration * rel;

            this.audio.currentTime = time;
        }
    }

