

    import {Options, prop, Vue} from 'vue-class-component';
    import {recordingFile} from "@/util";
    import ShareRecording from "@/components/ShareRecording.vue";
    import Player from "@/components/Player.vue";
    import Error from "@/components/Error.vue";

    class Props {
        id: string = prop({
            required: true,
        });
    }

    @Options({
        components: {Player, ShareRecording, Error}
    })
    export default class Recording extends Vue.with(Props) {

        id!: string;

        error: string | null = null;

        audio: HTMLAudioElement | null = null;

        mounted() {

            const file = recordingFile(this.id);

            console.log(file);

            this.audio = window.document.createElement('audio');
            this.audio.src = file;

            this.audio.addEventListener('error', e => {
                console.log(e);
                this.error = 'Die Aufnahme konnte leider nicht gefunden werden. Wahrscheinlich wurde sie gelöscht';
            })
        }
    }
